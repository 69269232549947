@use '@queries' as *;

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: sticky;
  width: 100%;
  overflow-y: hidden;
  right: 0;
  left: 0;
  top: 0;
  @include min-1440-break {
    gap: 18px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  //position: sticky;
  //top: 190px;

  background-color: var(--cst-content-background, #fff);
  z-index: 10;
  padding: 10px;

  @include min-1440-break {
    //top: 220px;
    //padding: 20px;
  }
}
