@use '@queries' as *;
@use '@typography' as *;

.sidebar-filter {
  display: flex;
  width: 242px;
  align-items: flex-start;
  gap: 18px;
  max-height: 818px;
  height: 100%;
  .sidebar-content {
    display: flex;
    flex-direction: column;
    gap: 18px;
    height: 100%;
    width: 100%;
    align-items: flex-start;

    .sidebar-title {
      color: var(--cst-side-bar-filter-text);
      @include typography-m;
      @include weight-semi-bold;
    }

    .items-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: 100%;
    }
  }
}

.divider {
  height: 535px;

  @include min-1440-break {
    height: 627px;
  }

  @include min-1920-break {
    height: 818px;
  }
}
