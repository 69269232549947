@use '@queries' as *;

.sidebar-sale {
  display: flex;
  width: 242px;
  align-items: flex-start;
  gap: 18px;
  max-height: 818px;
  height: 100%;

  .sidebar-content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 100%;
    width: 100%;
    align-items: flex-start;

    .text-block {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .sidebar-title {
        color: var(--cst-side-bar-category-title);
      }
      .sidebar-subtitle {
        color: var(--cst-side-bar-sale-description);
      }
    }

    .image {
      object-fit: contain;
      border-radius: 12px;
      width: 100%;
      height: auto;
    }

    .items-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: 100%;

      > button {
        cursor: pointer;
      }

      > div {
        width: 100%;
        padding: 11px 12px;
      }
    }
  }
}

.divider {
  height: 535px;

  @include min-1440-break {
    height: 627px;
  }

  @include min-1920-break {
    height: 818px;
  }
}
