@use '@typography' as *;
@use '@queries' as *;

.sort-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.sort-title {
  @include typography-base;
  @include min-1440-break {
    @include typography-m;
  }
}

.items_amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.sort_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}
