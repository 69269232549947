@use '@typography' as *;
@use '@queries' as *;

.sidebar-category {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 100%;
  width: 224px;
  align-items: flex-start;

  .sidebar-title {
    color: var(--cst-side-bar-category-title);
    @include typography-m;
    @include weight-semi-bold;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .top-slot,
    .bottom-slot {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
