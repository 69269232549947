@use '@queries' as *;
@use '@typography' as *;

.container {
  min-width: 161px;
  height: 48px;
  top: 230px;
  left: 20px;
  padding: 12px;
  border-radius: 10px;
  border: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: transparent;
  transition: all 0.2s ease;

  &:hover {
    background: var(--cst-side-baritems-background-active);
  }

  .arrow-icon {
    opacity: 0;
    transition: all 0.3s ease;
  }

  &.active {
    background: var(--cst-side-baritems-background-active);

    .arrow-icon {
      opacity: 1;
    }

    .content_container {
      .text {
        color: var(--cst-side-baritems-text-hover);
      }
    }
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  & .content_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .text {
      @include typography-base;
      @include weight-medium;
      color: var(--cst-side-bar-items-text-default);

      @include min-1440-break {
        @include typography-m;
      }

      &:hover {
        color: var(--cst-side-baritems-text-hover);
      }
    }
  }
}

.width {
  &--full-w {
    width: 100%;
  }

  &--content {
    width: max-content;
  }
}
