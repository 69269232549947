@use '@/shared/ui/button/adaptive' as *;
@use '@queries' as *;
@use '@typography' as *;

.sidebar {
  display: flex;
  width: 100dvw;
  height: 100%;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  max-width: 774px;
  flex: 1;
  z-index: 1;

  @include min-1440-break {
    max-width: 808px;
  }

  @include min-1920-break {
    max-width: 931px;
  }

  .content {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background: var(--cst-side-bar-background);
    z-index: 0;
    padding: 20px 18px 20px 20px;
    min-width: 262px;
    max-width: max-content;
    gap: 14px;

    @include min-1440-break {
      min-width: 288px;
      padding: 24px 24px 24px 40px;
      gap: 18px;
    }

    @include min-1920-break {
      padding: 24px 36px 24px 151px;
      min-width: 411px;
    }

    .droplist-item {
      padding: 11px 12px;
      width: 100%;
      cursor: pointer;

      &:hover {
        background: var(--cst-dropitem-background-hover);
        p {
          color: var(--cst-dropitem-text-hover);
        }
      }

      p {
        color: var(--cst-side-bar-items-text-default);
        @include typography-base;
        @include weight-medium;

        @include min-1440-break {
          @include typography-m;
        }
      }
    }
  }
}

.sidebar-widget {
  display: flex;
  position: relative;

  .slot {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    display: flex;
    flex: 1;

    min-height: calc(100dvh - 145px);

    @include min-1440-break {
      min-height: calc(100dvh - 168px);
    }
  }
}

.opened {
  transform: translate(0, 0);
  display: flex;
  width: max-content;
}

.closed {
  width: 0;
  height: 0;
  position: absolute;
}

.add-button {
  @include buttonSizes('36', '36', '36', '42', '42', '42');
  width: 100%;
}

.filters {
  display: flex;
  max-width: 100%;
}

.cover {
  display: flex;
  width: 100%;
  transition: all 0.35s ease;
  opacity: 0.15;
  background: #101010;
  overflow: hidden;
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
}
